<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Board of Directors</h1>
        </div>
      </div>
    </div>
    <div class="main container">
      <!-- <h2 class="subtitle is-3">Current Board Members</h2>
      <br />-->
      <div class="columns is-multiline">
        <div class="boardMembers">
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2Fzywiec%20headshot%20transparent.png?alt=media&token=c8b17a20-1b40-43e1-92b9-7a0db2ed9c74"
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="card-content">
              <p class="title">Maciej Smusz</p>
              <p class="subtitle">President</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2Fdaniel.png?alt=media&token=db32d974-0f73-4217-95e7-027cf32ac911"
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="card-content">
              <p class="title">Daniel Smela</p>
              <p class="subtitle">Vice President</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2Fyessica.png?alt=media&token=c01b5187-d7d0-4c1b-a8c3-c0ae642d4600"
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="card-content">
              <p class="title">Yessica Króżel</p>
              <p class="subtitle">Secretary</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2FMatt.png?alt=media&token=96a93dbe-b22e-4536-969f-f8c513d06598"
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title">Matthew Schmit</p>
                  <p class="subtitle">Treasurer</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2FJakub.png?alt=media&token=366cc078-e16c-47ba-9cdc-cdef4869306d"
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="card-content">
              <p class="title">Kuba Gubała</p>
              <p class="subtitle">Sergeant at Arms</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Board",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin-top: 30px;
}
.boardMembers {
  display: flex;
  flex-direction: row;
  .card {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .image {
      align-self: center;
      img {
        max-width: 150px;
        margin-top: 10px;
        border-radius: 3px;
        filter: drop-shadow(0 0 0.05rem black);
      }
    }
    .card-content {
      align-self: flex-end;
    }
  }
}
.org-description {
  margin-top: 50px;
}
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>